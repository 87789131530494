// pushSubscription.js
export async function subscribeToPushNotifications() {
    try {
      if (!("serviceWorker" in navigator)) {
        console.error("Service Worker not supported in this browser.");
        return;
      }
  
      // Register the service worker
      const register = await navigator.serviceWorker.ready;
  
      // Convert the VAPID public key to Uint8Array
      function urlBase64ToUint8Array(base64String) {
        const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding)
          .replace(/-/g, "+")
          .replace(/_/g, "/");
        const rawData = atob(base64);
        const outputArray = new Uint8Array(rawData.length);
        for (let i = 0; i < rawData.length; ++i) {
          outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
      }
  
      const applicationServerKey = urlBase64ToUint8Array(`${process.env.REACT_APP_PUBLIC_KEY}`)
  
      // Check if subscription exists
      const existingSubscription = await register.pushManager.getSubscription();
      if (existingSubscription) {
        console.log("Already subscribed");
        return;
      }
  
      // Subscribe to push notifications
      const subscription = await register.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: applicationServerKey,
      });
  
      // Send the subscription to the server
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/notify/save-subscription`, {
        method: "POST",
        body: JSON.stringify({subscription}),
        headers: {
          "Content-Type": "application/json",
        },
        credentials: 'include'
      });
  
      const data = await res.json();
      console.log("Subscription successful:", data);
    } catch (error) {
      console.error("Push Notification Subscription Failed:", error);
    }
  }
  