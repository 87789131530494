import React, { useEffect, Suspense, lazy } from 'react';
import { pdfjs } from 'react-pdf';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './App.css';
import ScrollToTop from './scrollToTop';
import LoadingView from './components/LoadingView';
import { subscribeToPushNotifications } from './pushNotifications';

// ✅ Lazy load components
const Home = lazy(() => import('./components/Home'));
const Orders = lazy(() => import('./components/Orders'));
const MakeOrder = lazy(() => import('./components/MakeOrder'));
const LoginPage = lazy(() => import('./components/LoginPage'));
const EmailVerify = lazy(() => import('./components/EmailVerify'));
const OrderSummary = lazy(() => import('./components/OrderSummary'));
const OrderSuccessPage = lazy(() => import('./components/OrderSuccessPage'));
const WebShare = lazy(() => import('./components/WebShare'));

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

const queryClient = new QueryClient();

const App = () => {

  useEffect(() => {
    subscribeToPushNotifications(); // Subscribe to push notifications
  }, []);

  function urlBase64ToUint8Array(base64String) {
    console.log("Original Base64 Key:", base64String); // Debugging step

    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');

    try {
      const rawData = window.atob(base64); // Check if this fails
      console.log("Decoded Key (atob):", rawData);
      const outputArray = new Uint8Array(rawData.length);
      for (let i = 0; i < rawData.length; i++) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    } catch (error) {
      console.error("Invalid Base64 Encoding:", error);
      throw new Error("Invalid Base64-encoded VAPID public key.");
    }
  }

  const subscribeUserToPush = async (registration) => {
    try {
      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        console.error('Push notification permission denied.');
        return;
      }

      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(`${process.env.REACT_APP_PUBLIC_KEY}`) // Replace with your actual public key
      });

      await fetch(`${process.env.REACT_APP_BASE_URL}/notify/save-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ subscription })
      });

      console.log('Push subscription saved successfully.');
    } catch (error) {
      console.error('Error subscribing to push notifications:', error);
    }
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ScrollToTop />
          {/* ✅ Suspense shows a fallback while loading components */}
          <Suspense fallback={<LoadingView />}>
            <Routes>
              <Route exact path='/' element={<Home />} />
              <Route exact path='/orders' element={<Orders />} />
              <Route exact path='/:shopId/make-your-order' element={<MakeOrder />} />
              <Route exact path='/login' element={<LoginPage />} />
              <Route exact path='/verify-mail' element={<EmailVerify />} />
              <Route exact path='/:shopId/order-summary' element={<OrderSummary />} />
              <Route exact path='/order-details' element={<OrderSuccessPage />} />
              <Route exact path='/web-share' element={<WebShare />} />
              <Route path="*" element={<h1>404 Not Found</h1>} /> {/* To handle unexpected routes */}
            </Routes>
          </Suspense>
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;