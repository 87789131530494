import { Box, Typography, CircularProgress } from "@mui/material";

const LoadingView = () => (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#F7F8FA",
      }}
    >
      <img src='/logoGif.gif' width={100} height={100} />
      <Typography variant="body1" sx={{ fontSize: "16px", color: "#888888" }}>
        Loading...
      </Typography>
    </Box>
  )

  export default LoadingView